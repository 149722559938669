import { defineComponent, toRef, Component } from 'vue';
import { DisciplineFilterOptionInformation } from '@/models/DisciplineFilterOptionInformation';
import { DisciplineOption } from './DisciplineOption.class';
import { IFilterProvider } from '@/interfaces/filter-providers/IFilterProvider';
import { InjectExternal } from '@/platform/InjectExternal';
import { FilterProviderKey } from '@/platform/FilterProviderKey';
import { DisciplineOptionTracking } from './DisciplineOption.tracking';
import Tracking from '../../tracking/Tracking.vue';

export default defineComponent({
	components: {
		Tracking: Tracking as Component,
	},
	props: {
		discipline: { type: DisciplineFilterOptionInformation, required: true },
		displayFacets: { type: Boolean, default: false },
		selectedDiscipline: { type: String, required: false },
	},
	setup: (props) => {
		const filterProvider = InjectExternal<IFilterProvider>(FilterProviderKey.FILTER_PROVIDER);
		const component = new DisciplineOption(filterProvider, props.discipline, toRef(props, 'selectedDiscipline'));
		const tracking = new DisciplineOptionTracking(component);

		return {
			discipline: props.discipline,
			displayFacets: props.displayFacets,
			disciplineOptionRef: component.disciplineOptionRef,
			trackingData: tracking.trackingData,
			getIconOf: component.getIconOf.bind(component),
			selectOption: component.selectOption.bind(component),
			facet: component.facet.bind(component),
		};
	},
});
