import { computed, ref } from 'vue';
import { DropdownFilter } from './DropdownFilter.class';
import { ControlEvent } from '@/models/filter-tracking/ControlEvent';
import { ITrackingInput } from '../tracking/ITrackingInput';
import { Action } from '@studyportals/datalake-event-tracker';

export class DropdownFilterTracking {
	private readonly filter: DropdownFilter;

	public constructor(filter: DropdownFilter) {
		this.filter = filter;
	}

	public trackingData = computed(() => {
		if (!this.filter.refOfTrackingProxy) return;

		const data: ITrackingInput = {
			elementRef: this.filter.refOfTrackingProxy,
			dataRef: this.filter.selectionId,
			action: ref(Action.SELECT),
			eventToSubscribe: ControlEvent.CHANGE,
			label: this.filter.filterKey,
		};
		return data;
	});
}
