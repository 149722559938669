import { IFilterProvider } from '@/interfaces/filter-providers/IFilterProvider';
import { FilterKey } from '../FilterKey';
import { Tag } from './Tag';
import { TuitionFeeInterval } from '@/presentation/tuition-fee/TuitionFeeInterval';

export class TuitionFeeTag extends Tag {
	public constructor(
		public readonly values: readonly string[],
		public readonly required: boolean,
		public readonly disabled: boolean,
		public readonly filterProvider: IFilterProvider
	) {
		super(FilterKey.TUITION_FEE, values, required, disabled, filterProvider);
	}

	protected getDisplayNameForSingleValue(value: string): string {
		const interval = new TuitionFeeInterval(value);

		if (interval.hasMaxOnly) {
			return `Tuition fee max`;
		}
		if (interval.hasMinOnly) {
			return `Tuition fee min`;
		}
		return `Tuition fee`;
	}

	protected async clearFilter(): Promise<void> {
		await this.filterProvider.clearFilter(this.key);
	}
}
