import { IFilterProvider } from '@/interfaces/filter-providers/IFilterProvider';
import { ILocationFilterProvider } from '@/interfaces/filter-providers/ILocationFilterProvider';
import { FilterKey } from '@/models/FilterKey';

export class AreaFilterSelection {
	public constructor(private filterProvider: IFilterProvider, private locationFilterProvider: ILocationFilterProvider) {
		this.filterProvider = filterProvider;
		this.locationFilterProvider = locationFilterProvider;
	}

	public async process(value: string): Promise<void> {
		const countryFilterSelection = this.filterProvider.getFilterSelection(FilterKey.COUNTRY);
		const countryId = this.locationFilterProvider.identifyCountryFor(value);

		const selection = [
			{
				key: FilterKey.AREA,
				value,
			},
		];

		if (!countryFilterSelection.includes(countryId)) {
			selection.push({
				key: FilterKey.COUNTRY,
				value: countryId,
			});
		}

		await this.filterProvider.processFiltersSelection(selection);
	}
}
