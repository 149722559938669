export class PerformDebounce {
	public for(callback: () => void, wait: number): () => void {
		const global = window as unknown as { Shared?: { debounce: (callback: () => void, wait: number) => () => void } };

		const debounceImpl = global.Shared ? global.Shared.debounce : null;
		if (debounceImpl) {
			return debounceImpl(callback, wait);
		}

		return () => callback();
	}
}
