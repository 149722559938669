import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withModifiers as _withModifiers, renderList as _renderList, Fragment as _Fragment, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-663acb22"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "FilterSearchBoxWrapper" }
const _hoisted_2 = ["value", "placeholder"]
const _hoisted_3 = {
  key: 0,
  class: "lnr-magnifier FilterSearchBoxIcon"
}
const _hoisted_4 = { class: "FilterSuggestionsWrapper" }
const _hoisted_5 = ["onClick"]
const _hoisted_6 = { ref: "refOfTrackingProxy" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Tracking = _resolveComponent("Tracking")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("input", {
        ref: "inputRef",
        type: "search",
        value: _ctx.input,
        class: "FilterSearchBox",
        placeholder: _ctx.placeholder,
        autocomplete: "off",
        onInput: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.onInput && _ctx.onInput(...args))),
        onBlur: _cache[1] || (_cache[1] = ($event: any) => (_ctx.onBlur()))
      }, null, 40 /* PROPS, NEED_HYDRATION */, _hoisted_2),
      (!_ctx.input)
        ? (_openBlock(), _createElementBlock("i", _hoisted_3))
        : (_openBlock(), _createElementBlock("i", {
            key: 1,
            class: "lnr-cross FilterSearchBoxIcon Clickable",
            onMousedown: _cache[2] || (_cache[2] = _withModifiers(($event: any) => (_ctx.onClearInputClick()), ["prevent"]))
          }, null, 32 /* NEED_HYDRATION */))
    ]),
    _createElementVNode("div", _hoisted_4, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.suggestions, (item, index) => {
        return (_openBlock(), _createElementBlock("p", {
          key: index,
          class: "FilterSuggestion",
          onClick: ($event: any) => (_ctx.onSuggestionChosen(item))
        }, _toDisplayString(item.name), 9 /* TEXT, PROPS */, _hoisted_5))
      }), 128 /* KEYED_FRAGMENT */))
    ]),
    _createElementVNode("div", _hoisted_6, null, 512 /* NEED_PATCH */),
    (_ctx.trackingData)
      ? (_openBlock(), _createBlock(_component_Tracking, {
          key: 0,
          data: _ctx.trackingData
        }, null, 8 /* PROPS */, ["data"]))
      : _createCommentVNode("v-if", true)
  ], 64 /* STABLE_FRAGMENT */))
}