<template>
	<div>
		<div class="LocationSwitcher" v-if="includeContinents">
			<div class="CountryButton Skeleton"></div>
			<div class="ContinentButton Skeleton"></div>
		</div>
		<ul class="CountryOptions">
			<li v-for="item in filterOptions" :key="item.optionValue" class="CountryOption">
				<div class="CountryCheckbox Skeleton"></div>
				<div class="CountryName Skeleton"></div>
			</li>
		</ul>
	</div>
</template>

<script lang="ts" src="./LocationSkeletonLoader.ts"></script>
<style lang="scss" src="./LocationSkeletonLoader.scss" scoped></style>
