import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { ref: "refOfTrackingProxy" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_MultiSelect = _resolveComponent("MultiSelect")!
  const _component_Tracking = _resolveComponent("Tracking")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_MultiSelect, {
      options: _ctx.options,
      label: _ctx.label,
      placeholder: _ctx.placeholder,
      entity: _ctx.entity,
      validationLabel: _ctx.validationLabel,
      helperMessage: _ctx.helperMessage,
      searchable: _ctx.searchable,
      disabled: _ctx.disabled,
      valid: _ctx.valid,
      "groups:": _ctx.groups,
      onUpdated: _ctx.onUpdated
    }, null, 8 /* PROPS */, ["options", "label", "placeholder", "entity", "validationLabel", "helperMessage", "searchable", "disabled", "valid", "groups:", "onUpdated"]),
    _createElementVNode("div", _hoisted_1, null, 512 /* NEED_PATCH */),
    (_ctx.trackingData)
      ? (_openBlock(), _createBlock(_component_Tracking, {
          key: 0,
          data: _ctx.trackingData
        }, null, 8 /* PROPS */, ["data"]))
      : _createCommentVNode("v-if", true)
  ], 64 /* STABLE_FRAGMENT */))
}