import { defineComponent, onMounted, onUnmounted, Component } from 'vue';
import { FilterProviderKey } from '@/platform/FilterProviderKey';
import { IFilterProvider } from '@/interfaces/filter-providers/IFilterProvider';
import { InjectExternal } from '../../platform/InjectExternal';
import { SelectedFiltersTracking } from './SelectedFilters.tracking';
import SelectedFilters from './SelectedFilters.class';
import Tracking from '../tracking/Tracking.vue';
import Tag from './tag/Tag.vue';
import SelectedFiltersSkeletonLoader from './skeleton-loader/SelectedFiltersSkeletonLoader.vue';

export default defineComponent({
	components: {
		Tracking: Tracking as Component,
		Tag: Tag as Component,
		SelectedFiltersSkeletonLoader: SelectedFiltersSkeletonLoader as Component,
	},
	setup: () => {
		const filterProvider = InjectExternal<IFilterProvider>(FilterProviderKey.FILTER_PROVIDER);
		const component = new SelectedFilters(filterProvider);
		const tracking = new SelectedFiltersTracking(component);

		onMounted(component.onMounted.bind(component));
		onUnmounted(component.onUnmounted.bind(component));

		return {
			tags: component.tags,
			filtersApplied: component.filtersApplied,
			removableFiltersCount: component.removableFiltersCount,
			filterTagContainerRef: component.tagContainer.tagContainerRef,
			clearAllButtonRef: component.clearAllButtonRef,
			shouldDisplayShowMore: component.tagContainer.shouldDisplayShowMore,
			shouldDisplayShowLess: component.tagContainer.shouldDisplayShowLess,
			clearAllButtonTrackingData: tracking.clearAllTrackingData,
			isBusy: component.busyIndicator.isBusy,
			showMore: component.tagContainer.showMore.bind(component),
			showLess: component.tagContainer.showLess.bind(component),
			clearAll: component.clearAll.bind(component),
		};
	},
});
