export class FilterOptionInformation {
	public constructor(
		public readonly optionName: string,
		public readonly optionValue: string,
		public readonly optionPath: string,
		public readonly optionTitle: string,
		public readonly optionShortTitle?: string,
		public readonly optionMetaTitle?: string
	) {}
}
