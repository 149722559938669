export enum FacetOptionValue {
	ATTENDANCES = "attendances",
	EDUCATIONAL_LEVEL = "educationalLevels",
	DENSITIES = "densities",
	DISCIPLINES = "disciplines",
	EDUCATIONAL_FORMS = "educationalForms",
	AREA_IDS = "areaIds",
	REGION_IDS = "regionIds",
	DEGREE_TYPES = "degreeTypes",
	SPECIAL_PROGRAMMES = "specialProgrammes",
	COUNTRY_ISO_CODES = "countryIsoCodes",
	ORGANISATIONS_IDS = "organisationIds",
	DURATIONS = "durations"
}
