const iconByDisciplineId = new Map<string, string>([
	['4', 'lnr-bubbles'],
	['6', 'lnr-balance'],
	['7', 'lnr-rulers'],
	['8', 'lnr-book2'],
	['9', 'lnr-pen2'],
	['10', 'lnr-first-aid'],
	['11', 'lnr-beaker'],
	['12', 'lnr-microscope'],
	['13', 'lnr-bubbles'],
	['20', 'lnr-magic-wand'],
	['23', 'lnr-briefcase'],
	['24', 'lnr-cli'],
	['25', 'lnr-briefcase'],
	['26', 'lnr-rulers'],
	['28', 'lnr-rulers'],
	['29', 'lnr-rulers'],
	['30', 'lnr-rulers'],
	['31', 'lnr-first-aid'],
	['32', 'lnr-rulers'],
	['33', 'lnr-rulers'],
	['34', 'lnr-rulers'],
	['37', 'lnr-rulers'],
	['38', 'lnr-beaker'],
	['39', 'lnr-rulers'],
	['40', 'lnr-beaker'],
	['45', 'lnr-briefcase'],
	['46', 'lnr-beaker'],
	['47', 'lnr-earth'],
	['48', 'lnr-balance'],
	['49', 'lnr-balance'],
	['52', 'lnr-beaker'],
	['53', 'lnr-magic-wand'],
	['54', 'lnr-tree'],
	['56', 'lnr-microscope'],
	['58', 'lnr-camera'],
	['59', 'lnr-microscope'],
	['60', 'lnr-microscope'],
	['62', 'lnr-microscope'],
	['63', 'lnr-magic-wand'],
	['64', 'lnr-boat'],
	['67', 'lnr-bubbles'],
	['68', 'lnr-magic-wand'],
	['69', 'lnr-magic-wand'],
	['70', 'lnr-bubbles'],
	['71', 'lnr-bubbles'],
	['72', 'lnr-bubbles'],
	['73', 'lnr-bubbles'],
	['74', 'lnr-pen2'],
	['75', 'lnr-bubbles'],
	['76', 'lnr-bubbles'],
	['77', 'lnr-pen2'],
	['78', 'lnr-bubbles'],
	['79', 'lnr-bubbles'],
	['80', 'lnr-bubbles'],
	['81', 'lnr-beaker'],
	['82', 'lnr-beaker'],
	['83', 'lnr-rulers'],
	['84', 'lnr-pen2'],
	['85', 'lnr-balance'],
	['86', 'lnr-briefcase'],
	['87', 'lnr-briefcase'],
	['88', 'lnr-briefcase'],
	['89', 'lnr-briefcase'],
	['90', 'lnr-first-aid'],
	['91', 'lnr-pen2'],
	['92', 'lnr-first-aid'],
	['93', 'lnr-briefcase'],
	['94', 'lnr-rulers'],
	['97', 'lnr-book2'],
	['98', 'lnr-book2'],
	['99', 'lnr-pen2'],
	['100', 'lnr-first-aid'],
	['101', 'lnr-briefcase'],
	['102', 'lnr-bubbles'],
	['103', 'lnr-bubbles'],
	['104', 'lnr-magic-wand'],
	['105', 'lnr-first-aid'],
	['106', 'lnr-first-aid'],
	['107', 'lnr-first-aid'],
	['108', 'lnr-cli'],
	['109', 'lnr-balance'],
	['110', 'lnr-bubbles'],
	['111', 'lnr-briefcase'],
	['112', 'lnr-microscope'],
	['113', 'lnr-first-aid'],
	['114', 'lnr-balance'],
	['115', 'lnr-balance'],
	['117', 'lnr-earth'],
	['118', 'lnr-beaker'],
	['119', 'lnr-earth'],
	['122', 'lnr-earth'],
	['123', 'lnr-earth'],
	['124', 'lnr-earth'],
	['125', 'lnr-earth'],
	['126', 'lnr-earth'],
	['127', 'lnr-earth'],
	['128', 'lnr-earth'],
	['130', 'lnr-cli'],
	['131', 'lnr-first-aid'],
	['132', 'lnr-microscope'],
	['133', 'lnr-briefcase'],
	['134', 'lnr-boat'],
	['219', 'lnr-balance'],
	['220', 'lnr-balance'],
	['221', 'lnr-balance'],
	['222', 'lnr-beaker'],
	['223', 'lnr-beaker'],
	['224', 'lnr-beaker'],
	['225', 'lnr-beaker'],
	['226', 'lnr-beaker'],
	['227', 'lnr-beaker'],
	['228', 'lnr-first-aid'],
	['229', 'lnr-first-aid'],
	['230', 'lnr-first-aid'],
	['231', 'lnr-first-aid'],
	['232', 'lnr-briefcase'],
	['233', 'lnr-briefcase'],
	['234', 'lnr-briefcase'],
	['235', 'lnr-briefcase'],
	['236', 'lnr-briefcase'],
	['237', 'lnr-briefcase'],
	['238', 'lnr-briefcase'],
	['239', 'lnr-briefcase'],
	['240', 'lnr-briefcase'],
	['241', 'lnr-briefcase'],
	['242', 'lnr-briefcase'],
	['243', 'lnr-briefcase'],
	['244', 'lnr-briefcase'],
	['245', 'lnr-briefcase'],
	['246', 'lnr-briefcase'],
	['247', 'lnr-briefcase'],
	['248', 'lnr-briefcase'],
	['249', 'lnr-briefcase'],
	['250', 'lnr-rulers'],
	['251', 'lnr-rulers'],
	['252', 'lnr-rulers'],
	['253', 'lnr-rulers'],
	['254', 'lnr-rulers'],
	['255', 'lnr-rulers'],
	['256', 'lnr-rulers'],
	['257', 'lnr-rulers'],
	['258', 'lnr-magic-wand'],
	['259', 'lnr-magic-wand'],
	['260', 'lnr-magic-wand'],
	['261', 'lnr-magic-wand'],
	['262', 'lnr-magic-wand'],
	['263', 'lnr-magic-wand'],
	['264', 'lnr-magic-wand'],
	['265', 'lnr-cli'],
	['266', 'lnr-pen2'],
	['267', 'lnr-pen2'],
	['268', 'lnr-pen2'],
	['269', 'lnr-pen2'],
	['270', 'lnr-pen2'],
	['271', 'lnr-pen2'],
	['272', 'lnr-pen2'],
	['273', 'lnr-pen2'],
	['274', 'lnr-bubbles'],
	['275', 'lnr-bubbles'],
	['276', 'lnr-bubbles'],
	['277', 'lnr-bubbles'],
	['278', 'lnr-bubbles'],
	['279', 'lnr-cli'],
	['280', 'lnr-cli'],
	['281', 'lnr-cli'],
	['282', 'lnr-cli'],
	['283', 'lnr-cli'],
	['284', 'lnr-cli'],
	['285', 'lnr-cli'],
	['286', 'lnr-cli'],
	['287', 'lnr-earth'],
	['288', 'lnr-earth'],
	['289', 'lnr-book2'],
	['290', 'lnr-book2'],
	['291', 'lnr-book2'],
	['292', 'lnr-book2'],
	['293', 'lnr-book2'],
	['294', 'lnr-book2'],
	['295', 'lnr-book2'],
	['297', 'lnr-microscope'],
	['298', 'lnr-microscope'],
	['299', 'lnr-microscope'],
	['300', 'lnr-tree'],
	['301', 'lnr-tree'],
	['302', 'lnr-tree'],
	['303', 'lnr-tree'],
	['304', 'lnr-tree'],
	['305', 'lnr-tree'],
	['306', 'lnr-camera'],
	['307', 'lnr-camera'],
	['308', 'lnr-camera'],
	['309', 'lnr-camera'],
	['310', 'lnr-camera'],
	['311', 'lnr-boat'],
	['312', 'lnr-boat'],
	['313', 'lnr-boat'],
	['314', 'lnr-earth'],
	['315', 'lnr-earth'],
	['316', 'lnr-bubbles'],
	['317', 'lnr-boat'],
	['318', 'lnr-magic-wand'],
	['319', 'lnr-boat'],
	['320', 'lnr-bubbles'],
	['321', 'lnr-microscope'],
	['322', 'lnr-briefcase'],
	['323', 'lnr-cli'],
	['324', 'lnr-briefcase'],
	['325', 'lnr-briefcase'],
	['326', 'lnr-beaker'],
	['327', 'lnr-beaker'],
	['328', 'lnr-magic-wand'],
	['329', 'lnr-cli'],
	['330', 'lnr-cli'],
	['331', 'lnr-cli'],
	['332', 'lnr-pen2'],
	['333', 'lnr-book2'],
	['334', 'lnr-beaker'],
	['335', 'lnr-first-aid'],
	['339', 'lnr-bubbles'],
	['340', 'lnr-rulers'],
	['341', 'lnr-balance'],
	['342', 'lnr-briefcase'],
	['343', 'lnr-first-aid'],
	['344', 'lnr-first-aid'],
	['345', 'lnr-book2'],
	['346', 'lnr-first-aid'],
	['347', 'lnr-bubbles'],
	['348', 'lnr-book2'],
	['349', 'lnr-book2'],
	['350', 'lnr-rulers'],
	['351', 'lnr-first-aid'],
	['352', 'lnr-briefcase'],
	['353', 'lnr-briefcase'],
	['354', 'lnr-beaker'],
	['355', 'lnr-book2'],
	['356', 'lnr-briefcase'],
	['357', 'lnr-beaker'],
	['358', 'lnr-book2'],
	['359', 'lnr-book2'],
	['360', 'lnr-book2'],
	['361', 'lnr-first-aid'],
	['362', 'lnr-briefcase'],
	['363', 'lnr-book2'],
	['364', 'lnr-bubbles'],
	['365', 'lnr-pen2'],
	['366', 'lnr-briefcase'],
	['367', 'lnr-rulers'],
	['368', 'lnr-first-aid'],
	['369', 'lnr-first-aid'],
	['370', 'lnr-book2'],
	['371', 'lnr-book2'],
	['372', 'lnr-first-aid'],
	['373', 'lnr-briefcase'],
	['374', 'lnr-magic-wand'],
	['375', 'lnr-magic-wand'],
	['376', 'lnr-magic-wand'],
]);

const disciplineGetDefaultIcon = (id: string): string | undefined => {
	return iconByDisciplineId.get(id);
};

export { disciplineGetDefaultIcon };
