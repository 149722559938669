import { IEvent } from '@studyportals/event-aggregation-service-interface';

export class ClearHighVisibilityCountryOptionsEvent implements IEvent {
	public static readonly EventType = 'SearchFilters:ClearHighVisibilityCountryOptionsEvent';

	public readonly timestamp = new Date();
	public readonly eventType = ClearHighVisibilityCountryOptionsEvent.EventType;

	public static is(event: IEvent): event is ClearHighVisibilityCountryOptionsEvent {
		return event.eventType === ClearHighVisibilityCountryOptionsEvent.EventType;
	}
}
