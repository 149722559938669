<template>
	<div class="SelectedFiltersContainer" :class="{ Visible: filtersApplied || isBusy }">
		<div class="SelectedFiltersHeader" v-if="filtersApplied || isBusy">
			Selected filters
			<button type="button" class="ClearButton" ref="clearAllButtonRef" @click="clearAll" v-if="removableFiltersCount > 0 && !isBusy">
				{{ removableFiltersCount === 1 ? 'Clear filter' : `Clear ${removableFiltersCount} filters` }}
				<i class="lnr-redo2 ButtonIcon"></i>
			</button>
			<Tracking v-if="clearAllButtonTrackingData" :data="clearAllButtonTrackingData"></Tracking>
		</div>

		<SelectedFiltersSkeletonLoader v-if="isBusy" />
		<div v-else class="FilterTagContainer Collapsed" ref="filterTagContainerRef">
			<div v-for="tag in tags" :key="tag.id">
				<Tag :tag="tag" />
			</div>
		</div>
		<div>
			<button type="button" class="ShowMoreButton" ref="showMoreButtonRef" @click="showMore" v-if="shouldDisplayShowMore">
				Show more
				<i class="lnr-plus ButtonIcon"></i>
			</button>
			<button type="button" class="ShowLessButton" ref="showLessButtonRef" @click="showLess" v-if="shouldDisplayShowLess">
				Show less
				<i class="lnr-minus ButtonIcon"></i>
			</button>
		</div>
	</div>
</template>

<script lang="ts" src="./SelectedFilters.ts"></script>
<style lang="scss" src="./SelectedFilters.scss" scoped></style>
