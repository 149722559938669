import { IFilterProvider } from '@/interfaces/filter-providers/IFilterProvider';
import { FilterKey } from '../FilterKey';
import { Tag } from './Tag';

export class OrganisationTag extends Tag {
	public constructor(
		public readonly values: readonly string[],
		public readonly required: boolean,
		public readonly disabled: boolean,
		public readonly filterProvider: IFilterProvider
	) {
		super(FilterKey.ORGANISATIONS, values, required, disabled, filterProvider);
	}

	protected getDisplayNameForSingleValue(): string {
		return 'University';
	}

	protected async clearFilter(): Promise<void> {
		await this.filterProvider.clearFilters([this.key, FilterKey.COUNTRY, FilterKey.AREA, FilterKey.CONTINENT]);
	}
}
