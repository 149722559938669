import { Component, defineComponent, provide, onUnmounted } from 'vue';

import AreaFilter from '../area/AreaFilter.vue';
import CountryCheckbox from '@/presentation/checkbox/country-checkbox/CountryCheckbox.vue';
import Tracking from '@/presentation/tracking/Tracking.vue';
import TrackedMultiSelect from '@/presentation/tracking/tracked-multi-select/TrackedMultiSelect.vue';
import { CountryFilter } from './CountryFilter.class';
import { IFilterProvider } from '@/interfaces/filter-providers/IFilterProvider';
import { FilterProviderKey } from '@/platform/FilterProviderKey';
import { ILocationFilterProvider } from '@/interfaces/filter-providers/ILocationFilterProvider';
import { CountryExpandability } from './CountryExpandability';
import { CountryFilterContext } from './CountryFilterContext';
import { CountryFilterSelection } from './CountryFilterSelection';
import { InjectExternal } from '../../../platform/InjectExternal';

export default defineComponent({
	components: {
		Areas: AreaFilter as Component,
		CountryCheckbox: CountryCheckbox as Component,
		Tracking: Tracking as Component,
		TrackedMultiSelect: TrackedMultiSelect as Component,
	},
	setup: () => {
		const filterProvider = InjectExternal<IFilterProvider>(FilterProviderKey.FILTER_PROVIDER);
		const locationFilterProvider = InjectExternal<ILocationFilterProvider>(FilterProviderKey.LOCATION_FILTER_PROVIDER);

		const countryFilterSelection = new CountryFilterSelection(filterProvider, locationFilterProvider);
		const context = new CountryFilterContext(filterProvider, locationFilterProvider, new CountryExpandability(), countryFilterSelection);

		provide(CountryFilterContext.key, context);

		const component = new CountryFilter(filterProvider, locationFilterProvider);

		onUnmounted(() => {
			countryFilterSelection.dispose();
			component.dispose();
		});

		return {
			countryFilterOptions: component.visibleCountryOptions.values,
			key: component.key,
			showMultiselect: component.countryDropdown.show,
			transformedCountryFormat: component.transformedCountryFormat,
			countryMultiselectRef: component.countryDropdown.countryMultiselectRef,
			processAreaFilterChange: component.processAreaFilterChange.bind(component),
			displayMultiselect: component.countryDropdown.display.bind(component.countryDropdown),
			onSelect: component.countryDropdown.onSelect.bind(component.countryDropdown),
		};
	},
});
