import { ref, Ref } from 'vue';
import { DisciplineFilterOptionInformation } from '@/models/DisciplineFilterOptionInformation';
import { disciplineGetDefaultIcon } from '../DisciplineGetDefaultIcon';
import { IFilterProvider } from '@/interfaces/filter-providers/IFilterProvider';
import { FilterKey } from '@/models/FilterKey';

export class DisciplineOption {
	public readonly disciplineOptionRef = ref<HTMLElement | null>(null);
	public readonly discipline: DisciplineFilterOptionInformation;

	private readonly filterProvider: IFilterProvider;
	private readonly selectedDiscipline: Ref<string | undefined>;

	public constructor(
		filterProvider: IFilterProvider,
		discipline: DisciplineFilterOptionInformation,
		selectedDiscipline: Ref<string | undefined>
	) {
		this.filterProvider = filterProvider;
		this.discipline = discipline;
		this.selectedDiscipline = selectedDiscipline;
	}

	public getIconOf(discipline: DisciplineFilterOptionInformation): string | undefined {
		return discipline.icon ?? disciplineGetDefaultIcon(discipline.optionValue);
	}

	public async selectOption(discipline: DisciplineFilterOptionInformation): Promise<void> {
		if (discipline.optionValue === this.selectedDiscipline.value) {
			return;
		}

		await this.filterProvider.processFilterSelection({
			key: FilterKey.DISCIPLINES,
			value: discipline.optionValue,
		});
	}

	public facet(optionValue: string): number {
		const filterFacets = this.filterProvider.getFacet(FilterKey.DISCIPLINES, optionValue);
		return filterFacets ?? 0;
	}
}
