import { inject } from 'vue';
import { FilterProviderKey } from './FilterProviderKey';

const injectExternal = <T>(key: FilterProviderKey): T => {
	const value = inject<T>(key);
	if (value === undefined) {
		throw new Error(`"${key}" was not provided`);
	}

	return value;
};

export { injectExternal as InjectExternal };
