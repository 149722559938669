import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-aa15f446"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "AreaFilterWrapper" }
const _hoisted_2 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AreaCheckbox = _resolveComponent("AreaCheckbox")!

  return (_openBlock(), _createElementBlock("ul", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.areaFilterOptions, (item) => {
      return (_openBlock(), _createElementBlock(_Fragment, {
        key: item.optionValue
      }, [
        (item.countryIdentity === _ctx.countryId && (_ctx.showArea || _ctx.isAreaSelected(item.optionValue)))
          ? (_openBlock(), _createElementBlock("li", _hoisted_2, [
              _createVNode(_component_AreaCheckbox, {
                filterKey: _ctx.key,
                optionName: item.optionName,
                optionValue: item.optionValue,
                onAreaFilterChange: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('areaFilterChange', $event)))
              }, null, 8 /* PROPS */, ["filterKey", "optionName", "optionValue"])
            ]))
          : _createCommentVNode("v-if", true)
      ], 64 /* STABLE_FRAGMENT */))
    }), 128 /* KEYED_FRAGMENT */))
  ]))
}