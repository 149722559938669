import { computed, ref } from 'vue';
import SelectedFilters from './SelectedFilters.class';
import { ControlEvent } from '@/models/filter-tracking/ControlEvent';
import { TrackingInput } from '@/models/filter-tracking/TrackingInput';
import { Action } from '@studyportals/datalake-event-tracker';

export class SelectedFiltersTracking {
	private readonly selectedFilters: SelectedFilters;

	public constructor(selectedFilters: SelectedFilters) {
		this.selectedFilters = selectedFilters;
	}

	public clearAllTrackingData = computed(() => {
		if (!this.selectedFilters.clearAllButtonRef.value) return;

		return new TrackingInput(
			ref(this.selectedFilters.clearAllButtonRef.value),
			this.selectedFilters.stringOfUniqueTagFilterKeys,
			ref(Action.CLEAR),
			ControlEvent.CLICK,
			'clear_all'
		);
	});
}
