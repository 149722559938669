<template>
	<div>
		<label class="CheckboxRow">
			<div>
				<input
					ref="checkboxInputRef"
					type="checkbox"
					class="CheckboxInput"
					:value="optionValue"
					:data-filter="filterKey"
					v-model="selectedOptions"
					@click="updateSelection()"
				/>
				<Tracking v-if="trackingData" :data="trackingData"></Tracking>
				<span>{{ optionName }}</span>
			</div>
			<div class="FacetContainer">
				<span v-if="displayFacets" class="Facet">{{ facet }}</span>
				<div class="AreaSwitcher" v-if="hasAreas" @click.stop.prevent="toggleExpandedAreas(optionValue)">
					<i class="ToggleIcon" :class="[expandCheckbox ? 'lnr-chevron-up' : 'lnr-chevron-down']"></i>
				</div>
			</div>
		</label>
	</div>
</template>

<script lang="ts" src="./CountryCheckbox.ts"></script>
<style lang="scss" scoped src="../Checkbox.scss"></style>
