<template>
	<ul class="RefineContentOptionsList">
		<li v-for="(item, index) in filterOptions" :key="index">
			<Checkbox :filterKey="key" :optionName="item.optionName" :optionValue="item.optionValue" />
		</li>
	</ul>
</template>

<script lang="ts" src="./ContinentFilter.ts"></script>
<style lang="scss" scoped src="./ContinentFilter.scss"></style>
