import { defineComponent, toRef, PropType, onMounted, Component } from 'vue';
import { DropdownFilter } from './DropdownFilter.class';
import Tracking from '@/presentation/tracking/Tracking.vue';
import { AutocompleteSuggestion } from '@/infrastructure/AutocompleteSuggestion';
import { FilterKey } from '@/models/FilterKey';
import { DropdownFilterTracking } from './DropdownFilter.tracking';

export default defineComponent({
	components: {
		Tracking: Tracking as Component,
	},
	props: {
		placeholder: { type: String, required: true },
		suggest: {
			type: Function as PropType<(query: string) => Promise<readonly AutocompleteSuggestion[]>>,
			required: true,
		},
		processSelection: {
			type: Function as PropType<(value: string) => Promise<void>>,
			required: true,
		},
		lastValidSelectionOption: { type: String, required: true },
		inputDisplay: { type: String, required: true },
		filterKey: { type: String as PropType<FilterKey>, required: true },
	},
	setup: (props) => {
		const component = new DropdownFilter(
			props.suggest,
			props.processSelection,
			props.filterKey,
			toRef(props, 'lastValidSelectionOption'),
			toRef(props, 'inputDisplay')
		);

		const tracking = new DropdownFilterTracking(component);

		onMounted(() => component.onMounted());

		return {
			placeholder: props.placeholder,
			input: component.input,
			inputRef: component.inputRef,
			suggestions: component.suggestions,
			trackingData: tracking.trackingData,
			refOfTrackingProxy: component.refOfTrackingProxy,
			onInput: component.onInput.bind(component),
			onBlur: component.onBlur.bind(component),
			onClearInputClick: component.onClearInputClick.bind(component),
			onSuggestionChosen: component.onSuggestionChosen.bind(component),
		};
	},
});
