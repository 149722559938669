import { FilterKey } from '@/models/FilterKey';
import { ControlEvent } from '@/models/filter-tracking/ControlEvent';
import { TrackingInput } from '@/models/filter-tracking/TrackingInput';
import { computed, ref } from 'vue';
import { Action } from '@studyportals/datalake-event-tracker';
import { DisciplineOption } from './DisciplineOption.class';

export class DisciplineOptionTracking {
	private readonly option: DisciplineOption;

	public constructor(option: DisciplineOption) {
		this.option = option;
	}

	public trackingData = computed(() => {
		const result = new TrackingInput(
			this.option.disciplineOptionRef,
			ref(this.option.discipline.optionValue),
			ref(Action.CLICK),
			ControlEvent.CLICK,
			FilterKey.DISCIPLINES
		);
		return result;
	});
}
