import { Component, defineComponent, inject, onUnmounted } from 'vue';

import { AreaFilter } from './AreaFilter.class';
import { CountryFilterContext } from '@/presentation/location/country/CountryFilterContext';
import AreaCheckbox from '@/presentation/checkbox/area-checkbox/AreaCheckbox.vue';
import Tracking from '@/presentation/tracking/Tracking.vue';

export default defineComponent({
	components: {
		AreaCheckbox: AreaCheckbox as Component,
		Tracking: Tracking as Component,
	},
	props: {
		countryId: {
			type: String,
			required: true,
		},
	},
	emits: ['areaFilterChange'],
	setup: (props) => {
		const countryFilterContext: CountryFilterContext | undefined = inject(CountryFilterContext.key);
		if (countryFilterContext == null) {
			throw new Error(`"${CountryFilterContext.key}" class was not provided for AreaFilter`);
		}

		const component = new AreaFilter(props.countryId, countryFilterContext.filterProvider, countryFilterContext.expandability);

		onUnmounted(() => component.dispose());

		return {
			key: component.key,
			areaFilterOptions: component.areaFilterOptions,
			showArea: component.showArea,
			isAreaSelected: component.isAreaSelected.bind(component),
		};
	},
});
