import { FilterKey } from '@/models/FilterKey';
import Filter from '@/Filter.class';
import { IFilterProvider } from '@/interfaces/filter-providers/IFilterProvider';
import { EventAggregationService } from '@/platform/EventAggregationService';

export default class AttendanceFilter extends Filter {
	public constructor(filterProvider: IFilterProvider, eventAggregationService?: EventAggregationService) {
		super(FilterKey.DELIVERY_METHOD, filterProvider, eventAggregationService);
	}
}
