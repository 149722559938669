import { IEvent, IEventAggregationService, ISubscriber } from '@studyportals/event-aggregation-service-interface';

export class EventAggregationServiceSubscription {
	private readonly eventAggregationService: IEventAggregationService;
	private readonly subscriber: ISubscriber<IEvent>;

	private disposed = false;

	public constructor(eventAggregationService: IEventAggregationService, subscriber: ISubscriber<IEvent>) {
		this.eventAggregationService = eventAggregationService;
		this.subscriber = subscriber;
	}

	public to(eventType: string, replay?: boolean | undefined): EventAggregationServiceSubscription {
		this.eventAggregationService.subscribeTo(
			eventType,
			{
				notify: (event: IEvent, sequence: number) => {
					if (this.disposed) {
						return;
					}

					return this.subscriber.notify(event, sequence);
				},
			},
			replay
		);
		return this;
	}

	public dispose(): void {
		this.disposed = true;
	}
}
