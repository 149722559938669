import { ClearFiltersRequestedEvent } from '@/events/ClearFiltersRequestedEvent';
import { IFilterProvider } from '@/interfaces/filter-providers/IFilterProvider';
import { FilterKey } from '@/models/FilterKey';
import { EventAggregationService } from '@/platform/EventAggregationService';
import { ISubscriber } from '@studyportals/event-aggregation-service-interface';
import { EventAggregationServiceSubscription } from '@/platform/EventAggregationServiceSubscription';

export class ClearFilters implements ISubscriber<ClearFiltersRequestedEvent> {
	private readonly eventAggregationServiceSubscription: EventAggregationServiceSubscription;
	private readonly filterProvider: IFilterProvider;
	private isBusy = false;
	private disposed = false;

	private readonly filtersWithSpecificClearProcess = [FilterKey.COUNTRY];

	public constructor(filterProvider: IFilterProvider, eventAggregationService?: EventAggregationService) {
		this.filterProvider = filterProvider;

		eventAggregationService ??= EventAggregationService.instance;
		this.eventAggregationServiceSubscription = eventAggregationService.subscribe(this).to(ClearFiltersRequestedEvent.EventType);
	}

	public dispose(): void {
		this.eventAggregationServiceSubscription.dispose();
	}

	public async notify(event: ClearFiltersRequestedEvent): Promise<void> {
		if (ClearFiltersRequestedEvent.is(event)) {
			await this.clearFilters(event);
		}
	}

	public clearFilters = async (event: ClearFiltersRequestedEvent): Promise<void> => {
		const filters = event.filters.filter((filter) => !this.filtersWithSpecificClearProcess.includes(filter.key));
		if (filters.length === 0) {
			return;
		}

		await this.filterProvider.clearSelection(filters);
	};

	public clearAll = async (filterKeys: FilterKey[]): Promise<void> => {
		await this.filterProvider.clearFilters(filterKeys);
	};
}
