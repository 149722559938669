import { computed, ref } from 'vue';
import { FilterKey } from '@/models/FilterKey';
import { DisciplineFilterOptionInformation } from '@/models/DisciplineFilterOptionInformation';
import { IFilterProvider } from '@/interfaces/filter-providers/IFilterProvider';
import { DisciplineVisibility } from './DisciplineVisibility';
import { EventAggregationService } from '../../platform/EventAggregationService';
import Filter from '@/Filter.class';

export default class DisciplineFilter extends Filter {
	public disciplineVisibility: DisciplineVisibility;
	public readonly allDisciplinesButtonRef = ref<HTMLElement | null>(null);

	public constructor(filterProvider: IFilterProvider, eventAggregationService?: EventAggregationService) {
		super(FilterKey.DISCIPLINES, filterProvider, eventAggregationService);

		this.disciplineVisibility = new DisciplineVisibility(this.selectedDiscipline, this.selectedParentIdentity);
	}

	public isDisciplineSelected = computed((): boolean => {
		return this.selectedDiscipline.value !== undefined;
	});

	public selectedDiscipline = computed((): string => {
		return this.filterProvider.getFilterSelection(this.key)[0];
	});

	public disciplineFilterOptions = computed((): DisciplineFilterOptionInformation[] => {
		const result = this.filterOptions as DisciplineFilterOptionInformation[];
		return result;
	});

	public selectedParentIdentity = computed(() => {
		const disciplineId = this.selectedDiscipline.value;

		if (!disciplineId) {
			return;
		}

		const filterOption = (this.filterOptions as DisciplineFilterOptionInformation[]).filter((_) => _.optionValue === disciplineId)[0];

		if (filterOption) {
			return filterOption.parentIdentity;
		}

		return;
	});

	public displayFacets = computed((): boolean => {
		return this.filterProvider.displayFacets;
	});

	public async removeSelection(): Promise<void> {
		await this.filterProvider.processFilterSelection({
			key: this.key,
			value: this.selectedDiscipline.value,
		});
	}
}
