import { ref, Ref } from 'vue';
import { FilterKey } from '@/models/FilterKey';
import Checkbox from '@/presentation/checkbox/Checkbox.class';
import { IFilterProvider } from '@/interfaces/filter-providers/IFilterProvider';
import { ILocationFilterProvider } from '@/interfaces/filter-providers/ILocationFilterProvider';
import { CountryExpandability } from '@/presentation/location/country/CountryExpandability';
import { CountryFilterSelection } from '@/presentation/location/country/CountryFilterSelection';

export class CountryCheckbox extends Checkbox {
	public readonly expandCheckbox: Ref<boolean>;
	public readonly filterKey = FilterKey.COUNTRY;

	public checkboxInputRef = ref<HTMLInputElement | null>(null);

	private countryFilterSelection: CountryFilterSelection;
	private readonly countryExpandability: CountryExpandability;

	public constructor(
		public readonly optionName: string,
		public readonly optionValue: string,
		public readonly hasAreas: boolean,
		public readonly filterProvider: IFilterProvider,
		public readonly locationFilterProvider: ILocationFilterProvider,
		countryExpandability: CountryExpandability,
		countryFilterSelection: CountryFilterSelection
	) {
		super(optionName, optionValue, FilterKey.COUNTRY, filterProvider);

		this.expandCheckbox = this.countryExpandability?.isExpandedFor(optionValue);
		this.countryExpandability = countryExpandability;
		this.countryFilterSelection = countryFilterSelection;
	}

	public async updateSelection(): Promise<void> {
		this.updateTackingAction();
		if (this.optionValue === undefined) {
			return;
		}

		return await this.countryFilterSelection.process([this.optionValue]);
	}

	public toggleExpandedAreas(countryId: string | undefined): void {
		if (countryId == null) {
			return;
		}
		this.countryExpandability.toggleExpandability(countryId);
	}
}
